import {Component} from "react";
import React from "react";
import {TextField} from "@mui/material";

type OptionsProps = {
    onSwitch:any
}

type OptionsState = {
    height:number,
    width:number,
    left:number,
    right:number,
    top:number
}

class Options extends Component<OptionsProps, OptionsState> {

    constructor(props: OptionsProps, context: any) {
        super(props, context)
        this.state={
            width:1000,
            height:1800,
            left:400,
            right:400,
            top:400
        }
    }

    onChangeWidth=(val:any)=>{
        const width=parseInt(val.target.value)
        this.setState({width},()=>{
            this.props.onSwitch(this.state)
        })
    }

    onChangeHeight=(val:any)=>{
        const height=parseInt(val.target.value)
        this.setState({height},()=>{
            this.props.onSwitch(this.state)
        })
    }

    private onChangeLeft(val: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
        const left=parseInt(val.target.value)
        this.setState({left},()=>{
            this.props.onSwitch(this.state)
        })
    }

    private onChangeRight(val: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
        const right=parseInt(val.target.value)
        this.setState({right},()=>{
            this.props.onSwitch(this.state)
        })

    }

    private onChangeTop(val: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
        const top=parseInt(val.target.value)
        this.setState({top},()=>{
            this.props.onSwitch(this.state)
        })

    }
    render() {
        return (<div>

            <TextField  inputProps={{step: "20"}} type="number" label="ширина"  defaultValue={this.state.width} onChange={(val)=>this.onChangeWidth(val)}/>
            <TextField inputProps={{step: "20"}} type="number" label="высота" defaultValue={this.state.height} onChange={(val)=>this.onChangeHeight(val)}/>
            <TextField inputProps={{step: "50"}} type="number" label="лево" defaultValue={this.state.left} onChange={(val)=>this.onChangeLeft(val)}/>
            <TextField inputProps={{step: "50"}} type="number" label="право" defaultValue={this.state.right} onChange={(val)=>this.onChangeRight(val)}/>
            <TextField inputProps={{step: "50"}} type="number" label="вверх" defaultValue={this.state.top} onChange={(val)=>this.onChangeTop(val)}/>
        </div>)
    }


}

export default Options
