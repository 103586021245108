import * as React from 'react';
import {Grid, Typography} from "@mui/material";
import './index.css'
import SVGforms from './svg'
import {Form} from "../index";

type DoorFormProps={
    onSwitch:any,
    onMountForm:any,
    defaultModel:any
}

type DoorFormState={
}

type FormItems={
    label:string;
    preview:string;
    component:React.ReactElement;
}
export class DoorForm extends React.Component<DoorFormProps,DoorFormState> {

    formItems1:Array<FormItems>;
    formItems2:Array<FormItems>;


    constructor(props: DoorFormProps, context: any) {
        super(props, context);
        const onMountForm=(form:any)=>{
            if(props.onMountForm){
                props.onMountForm(form)
            }
        }
        this.formItems1=[
            {label:"a1",preview:SVGforms.Form1.preview,
                component:<SVGforms.Form1.component onMount={onMountForm} defaultModel={props.defaultModel}/>},
            {label:"a2",preview:SVGforms.Form2.preview,
                component:<SVGforms.Form2.component onMount={onMountForm} defaultModel={props.defaultModel}/>},
            {label:"a5",preview:SVGforms.Form5.preview,
                component:<SVGforms.Form5.component onMount={onMountForm} defaultModel={props.defaultModel}/>},
        ]
        this.formItems2=[
            {label:"a3",preview:SVGforms.Form3.preview,
                component:<SVGforms.Form3.component onMount={onMountForm} defaultModel={props.defaultModel}/>},
            {label:"a4",preview:SVGforms.Form4.preview,
                component:<SVGforms.Form4.component onMount={onMountForm} defaultModel={props.defaultModel}/>},
        ]
    }

    onClickItem=(item:any)=>{
        this.props.onSwitch(item)
    }
    render = () => {
        return (
            <div>
            <Grid container>
                <Grid item xs={6}>
                    {this.formItems1.map((item)=>{
                        return <div className='formItem' onClick={()=>this.onClickItem(item.component)}>
                            <img src={item.preview} />
                            <Typography>{item.label}</Typography>
                        </div>
                    })}
                </Grid>
                <Grid item xs={6}>
                    {this.formItems2.map((item)=>{
                        return <div className='formItem' onClick={()=>this.onClickItem(item.component)}>
                            <img src={item.preview} />
                            <Typography>{item.label}</Typography>
                        </div>
                    })}
                </Grid>
            </Grid>
            </div>
        );
    }
}
