import {Component} from "react";
import React from "react";
import Model1Preview from './model1.svg'
import './model1.css'
import {BaseModel} from "./baseModel";

class Model1 extends BaseModel {


    render() {
        return (
            <svg id="Horisontal" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 143 298">
                <defs>

                    <linearGradient id="BG-gradient" x1="4.13" y1="148.59" x2="145.52" y2="148.59"
                                    gradientUnits="userSpaceOnUse">
                        <stop offset="0.25" stopColor="#f9f9f9"/>
                        <stop offset="0.67" stopColor="#e8e8e8"/>
                        <stop offset="0.79" stopColor="#e2e2e2"/>
                    </linearGradient>
                    <linearGradient id="handle-gradient" x1="11.29" y1="150.41" x2="16.95" y2="150.41"
                                    gradientUnits="userSpaceOnUse">
                        <stop offset="0.01" stopColor="#babbbc"/>
                        <stop offset="0.29" stopColor="#989a9e"/>
                        <stop offset="0.65" stopColor="#bdbdc1"/>
                        <stop offset="0.87" stopColor="#9d9fa2"/>
                        <stop offset="1" stopColor="#a6a8aa"/>
                        <stop offset="1" stopColor="#c2c5c9"/>
                    </linearGradient>
                    <linearGradient id="inox-gradient" x1="38.84" y1="170.92" x2="102.47" y2="170.92"
                                    />
                    <linearGradient id="inox-gradient-2" data-name="inox" x1="38.84" y1="213.34" x2="102.47" y2="213.34"
                                    />
                    <linearGradient id="inox-gradient-3" data-name="inox" x1="38.84" y1="128.5" x2="102.47" y2="128.5"
                                    />
                    <linearGradient id="inox-gradient-4" data-name="inox" x1="38.84" y1="86.08" x2="102.47" y2="86.08"
                                    />
                    <linearGradient id="glass-gradient" x1="70.65" y1="92.51" x2="70.65" y2="79.78"
                                    gradientUnits="userSpaceOnUse">
                        <stop offset="0" stopColor="#f4f4f4"/>
                        <stop offset="0.5" stopColor="#dee8e8"/>
                        <stop offset="1" stopColor="#d1cece"/>
                    </linearGradient>
                    <linearGradient id="glass-gradient-2" data-name="glass-gradient" x1="70.65" y1="134.93" x2="70.65"
                                    y2="122.2" />
                    <linearGradient id="glass-gradient-3" data-name="glass-gradient" x1="70.65" y1="177.35" x2="70.65"
                                    y2="164.62" />
                    <linearGradient id="glass-gradient-4" data-name="glass-gradient" x1="70.65" y1="219.76" x2="70.65"
                                    y2="207.04" />
                    <filter id="inset-shadow">
                        <feOffset dx="0" dy="0"/>
                        <feGaussianBlur stdDeviation="1" result="offset-blur"/>
                        <feComposite operator="out" in="SourceGraphic" in2="offset-blur" result="inverse"/>
                        <feFlood floodColor="black" floodOpacity="1" result="color"/>
                        <feComposite operator="in" in="color" in2="inverse" result="shadow"/>
                        <feComponentTransfer in="shadow" result="shadow">
                            <feFuncA type="linear" slope="1.25"/>
                        </feComponentTransfer>
                        <feComposite operator="over" in="shadow" in2="SourceGraphic"/>
                    </filter>
                </defs>
                <pattern id="doorPattern" width="143" height="298">
                    <image href={this.state.bgColor} className="fillBG"/>
                </pattern>
                <pattern id="glassPattern" width="66"  height="12.73">
                    <image href={this.state.glassColor}/>
                </pattern>
                <title>model</title>
                <g id="H01">
                    <rect id="BG" x="0" y="0" width="143" height="298" fill="url(#doorPattern)"/>
                    <polyline id="doorstroke" className="cls-2"
                              points="5.61 295.05 135.7 295.05 135.7 5.77 5.61 5.77 5.61 295.05" />
                    <polyline id="doorstroke2" className="cls-2"
                              points="22.01 278.66 119.29 278.66 119.29 22.18 22.01 22.18 22.01 278.66" />
                    <rect id="handle" className="handle" x="11.3" y="65.58" width="5.66" height="169.5"/>
                    <polyline id="inox" data-name="inox" className="cls-4"
                              points="38.84 181.52 102.47 181.52 102.47 160.31 38.84 160.31 38.84 181.52" />
                    <polyline id="inox-2" data-name="inox" className="cls-5"
                              points="38.84 223.94 102.47 223.94 102.47 202.73 38.84 202.73 38.84 223.94" />
                    <polyline id="inox-3" data-name="inox" className="cls-6"
                              points="102.47 117.9 38.84 117.9 38.84 139.1 102.47 139.1 102.47 117.9" />
                    <polyline id="inox-4" data-name="inox" className="cls-7"
                              points="102.47 75.48 38.84 75.48 38.84 96.69 102.47 96.69 102.47 75.48" />
                    <polyline id="glass"
                              points="98.22 79.78 43.08 79.78 43.08 92.51 98.22 92.51 98.22 79.78" fill="url(#glassPattern)"/>
                    <polyline id="glass-2" data-name="glass"
                              points="98.22 122.2 43.08 122.2 43.08 134.93 98.22 134.93 98.22 122.2" fill="url(#glassPattern)"/>
                    <polyline id="glass-3" data-name="glass"
                              points="98.22 164.62 43.08 164.62 43.08 177.35 98.22 177.35 98.22 164.62" fill="url(#glassPattern)"/>
                    <polyline id="glass-4" data-name="glass"
                              points="98.22 207.04 43.08 207.04 43.08 219.76 98.22 219.76 98.22 207.04" fill="url(#glassPattern)"/>
                </g>
            </svg>
        )
    }
}

export default {component:Model1,preview:Model1Preview}
