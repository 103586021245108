import {Component} from "react";

type BaseFormProps = {
    onMount:any,
    defaultModel:any
}

type BaseFormState = {
    innerComponent:any,
    glassColor:any,
    bgColor:any,
    height:any,
    width:any,
    dh:number,
    dw:number,
    view:number,
    left:number,
    right:number,
    top:number
}


export abstract class BaseForm extends Component<BaseFormProps, BaseFormState> {

    baseOptions={
        width:1000,
        height:1800,
        left:400,
        right:400,
        top:400
    }

    constructor(props: BaseFormProps, context: any) {
        super(props, context)
        this.state={
            innerComponent:props.defaultModel,
            glassColor:"",
            bgColor:"",
            height:90,
            width:50,
            dh:1,
            dw:1,
            view:1,
            left:25,
            right:25,
            top:16
        }
    }

    getBaseScale=()=>{
        return "scale("+(4*this.state.view)+" 4)"
    }
    getScale=(baseX:number,baseY:number)=>{
        return "scale("+(baseX===0?1:baseX)*this.state.dw+","+(baseY===0?1:baseY)*this.state.dh+")"
    }

    setView=(view:number)=>{
        this.setState({view})
    }
    setOptions=(option:any)=>{
        this.setState({
            width:option.width/20,
            height:option.height/20,
            dw:option.width/this.baseOptions.width,
            dh:option.height/this.baseOptions.height,
            left:option.left/this.baseOptions.left,
            right:option.right/this.baseOptions.right,
            top:option.top/this.baseOptions.top
        })
    }

    componentDidMount=()=>{
        if(this.props.onMount){
            this.props.onMount(this)
        }
    }

    setInnerComponent=(item:any)=>{
        this.setState({
            innerComponent:item
        })
    }

    setGlassColor=(color:string)=>{
        this.setState({glassColor:color})
    }

    setColor=(color:string)=>{
        this.setState({bgColor:color})
    }
}