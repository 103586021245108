import {Component} from "react";
import React from "react";
import './index.css'
import {Button, ButtonGroup} from "@mui/material";

type InOutButtonsProps = {
    onSwitchView:any
}

type InOutButtonsState = {}

class InOutButtons extends Component<InOutButtonsProps, InOutButtonsState> {

    constructor(props: InOutButtonsProps, context: any) {
        super(props, context)

    }

    onSwithView=(view:number)=>{
        this.props.onSwitchView(view)
    }

    render() {
        return (<div>
            <ButtonGroup variant="contained" aria-label="outlined primary button group" className="buttonGroup">
                <Button onClick={()=>this.onSwithView(1)}>Вид снаружи</Button>
                <Button onClick={()=>this.onSwithView(-1)}>Вид внутри</Button>

            </ButtonGroup>
        </div>)
    }
}

export default InOutButtons
