import {Component} from "react";
import React from "react";
import {Grid, Typography} from "@mui/material";
import ColorImgs from "./imgs";


type DoorColorProps = {
    onSwitch:any
}

type DoorColorState = {}

class DoorColor extends Component<DoorColorProps, DoorColorState> {
    colorItems1=[
        {label:"c1",preview:ColorImgs.Color1,component:ColorImgs.Color1},
        {label:"c2",preview:ColorImgs.Color2,component:ColorImgs.Color2}
    ]
    colorItems2=[
        {label:"c3",preview:ColorImgs.Color3,component:ColorImgs.Color3},
        {label:"c4",preview:ColorImgs.Color4,component:ColorImgs.Color4}
    ]


    constructor(props: DoorColorProps, context: any) {
        super(props, context)

    }

    onClickItem=(item:any)=>{
        this.props.onSwitch(item)
    }

    render() {
        return (            <div>
            <Grid container>
                <Grid item xs={6}>
                    {this.colorItems1.map((item)=>{
                        return <div className='formItem' onClick={()=>this.onClickItem(item.component)}>
                            <img src={item.preview} />
                            <Typography>{item.label}</Typography>
                        </div>
                    })}
                </Grid>
                <Grid item xs={6}>
                    {this.colorItems2.map((item)=>{
                        return <div className='formItem' onClick={()=>this.onClickItem(item.component)}>
                            <img src={item.preview} />
                            <Typography>{item.label}</Typography>
                        </div>
                    })}
                </Grid>
            </Grid>
        </div>)
    }
}

export default DoorColor
