import * as React from 'react';
import {Grid, Typography} from "@mui/material";
import SVGmodels from "./svg";


type DoorModelProps={
    onSwitch:any,
    onMountModel:any
}

type DoorModelState={

}
type ModelItems={
    label:string;
    preview:string;
    component:React.ReactElement;
}

export class DoorModel extends React.Component<DoorModelProps,DoorModelState> {
    modelItems1:Array<ModelItems>
    modelItems2:Array<ModelItems>

    constructor(props: DoorModelProps, context: any) {
        super(props, context);
        const onMountModel=(model:any)=>{
            if(props.onMountModel){
                props.onMountModel(model)
            }
        }
        this.modelItems1=[
            {label:"b1",preview:SVGmodels.Model1.preview,component:<SVGmodels.Model1.component onMount={onMountModel}/>},
            {label:"b2",preview:SVGmodels.Model2.preview,component:<SVGmodels.Model2.component onMount={onMountModel}/>},
        ]
        this.modelItems2=[
            {label:"b3",preview:SVGmodels.Model4.preview,component:<SVGmodels.Model3.component onMount={onMountModel}/>},
            {label:"b4",preview:SVGmodels.Model4.preview,component:<SVGmodels.Model4.component onMount={onMountModel}/>},
        ]
    }

    onClickItem=(item:any)=>{
        this.props.onSwitch(item)
    }

    render = () => {
        return (
            <div>
                <Grid container>
                    <Grid item xs={6}>
                        {this.modelItems1.map((item)=>{
                            return <div className='formItem' onClick={()=>this.onClickItem(item.component)}>
                                <img src={item.preview} />
                                <Typography>{item.label}</Typography>
                            </div>
                        })}
                    </Grid>
                    <Grid item xs={6}>
                        {this.modelItems2.map((item)=>{
                            return <div className='formItem' onClick={()=>this.onClickItem(item.component)}>
                                <img src={item.preview} />
                                <Typography>{item.label}</Typography>
                            </div>
                        })}
                    </Grid>
                </Grid>
            </div>
        );
    }
}
