import {Component} from "react";
import React from "react";
import Form4Preview from './form4.svg'
import {Form} from "../../index";
import Models from "../../doorModel/svg";
import './index.css'
import {BaseForm} from "./baseForm";


class Form4 extends BaseForm implements Form {

    render() {
        return (<div className="svgContent">
            <svg width="97" height="90" fill="none" xmlns="http://www.w3.org/2000/svg" transform={this.getBaseScale()}>
                <pattern id="glassPattern" width="66" height="12.73">
                    <image href={this.state.glassColor}/>
                </pattern>
                <pattern id="formPattern" width="143" height="298">
                    <image href={this.state.bgColor} className="fillBG"/>
                </pattern>

                <path d="M72 1H96V89H72V1Z" fill="url(#glassPattern)" fillOpacity="0.4" stroke="url(#formPattern)"
                      strokeWidth="2" transform={this.getScale(1, 0)}/>
                <path d="M1 1H25V89H1V1Z" fill="url(#glassPattern)" fillOpacity="0.4" stroke="url(#formPattern)"
                      strokeWidth="2" transform={this.getScale(1, 0)}/>
                <g transform={this.getScale(1, 1)}>
                    <rect x="25" y="1" width="47" height="88" stroke="url(#formPattern)" strokeWidth="2"/>
                    {this.state.innerComponent}
                </g>
            </svg>
        </div>)
    }
}

export default {component: Form4, preview: Form4Preview}
