import {Component} from "react";
import React from "react";
import Form1Preview from './form1.svg'
import {Form} from "../../index";
import './index.css'
import Models from '../../doorModel/svg/index'
import {BaseForm} from "./baseForm";


class Form1 extends BaseForm implements Form{


    render() {
        return (
            <div className="svgContent">
            <svg
                 fill="none" xmlns="http://www.w3.org/2000/svg" transform={this.getBaseScale()}>
                <pattern id="formPattern" width="143" height="298">
                    <image href={this.state.bgColor} className="fillBG"/>
                </pattern>
                <rect x="1" y="1" stroke="url(#formPattern)" strokeWidth="2">

                </rect>
                <g transform={this.getScale(1,1)}>
                <svg y="2" >
                {this.state.innerComponent}
                </svg>
                </g>
            </svg>

            </div>
        )
    }
}

export default {component:Form1,preview:Form1Preview}
