import * as React from 'react';
import './index.css'
import {Typography} from "@mui/material";
import {ReactNode} from "react";


type MenuState = {
    title: string,
    body: ReactNode,
    visible:boolean
}

type MenuProps={

}

export class SecondMenu extends React.Component<MenuProps,MenuState> {

    constructor(props: any, context: any) {
        super(props, context);
        this.state={title:"",
            body:<React.Fragment/>,
            visible:false}
    }

    show=(title:any,component:any)=>{
        this.setState({title,body:component,visible:true})
    }
    hide=()=>{
        this.setState({title:"",
            body:<React.Fragment/>,
            visible:false})
    }
    render = () => {
        return (
            <div hidden={!this.state.visible} className="secondMenu">
                <Typography>{this.state.title}</Typography>
                <div>{this.state.body}</div>
            </div>
        );
    }

    onClickItem=(key:any)=> {
        console.log(key)
    }
}
