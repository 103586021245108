import {Component} from "react";
import React from "react";
import Form5Preview from './form5.svg'
import {Form} from "../../index";
import Models from "../../doorModel/svg";
import './index.css'
import {BaseForm} from "./baseForm";


class Form5 extends BaseForm implements Form {

    render() {
        return (<div className="svgContent">
            <svg width="97" height="105"  fill="none" xmlns="http://www.w3.org/2000/svg"
                 transform={this.getBaseScale()}>
                <pattern id="glassPattern" width="66" height="12.73">
                    <image href={this.state.glassColor}/>
                </pattern>
                <pattern id="formPattern" width="143" height="298">
                    <image href={this.state.bgColor} className="fillBG"/>
                </pattern>

                <path d="M1 16 H25 V105 H1 V16 Z" fill="url(#glassPattern)" fillOpacity="0.4" stroke="url(#formPattern)"
                      strokeWidth="2" transform={this.getScale(1, 0)}/>
                <path d="M72 16 H96 V105 H72 V16 Z" fill="url(#glassPattern)" fillOpacity="0.4"
                      stroke="url(#formPattern)" strokeWidth="2" transform={this.getScale(1, 0)}/>
                <path d="M1 1H96V16H1V1Z" fill="url(#glassPattern)" fillOpacity="0.4" stroke="url(#formPattern)"
                      strokeWidth="2" transform={this.getScale(0, 1)}/>
                <g transform={this.getScale(1, 1)}>
                    <rect x="25" y="16" width="47" height="90" stroke="url(#formPattern)" strokeWidth="2"/>
                    <svg y="16" height="90">
                        {this.state.innerComponent}
                    </svg>
                </g>
            </svg>

        </div>)
    }
}

export default {component: Form5, preview: Form5Preview}
