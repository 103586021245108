import * as React from 'react';
import {ReactNode} from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import {Grid, Typography} from "@mui/material";
import {DoorModel} from "./doorModel";
import {DoorForm} from "./doorForm";
import {SecondMenu} from "./secondMenu";
import DoorColor from "./doorColor";
import ColorImgs from './doorColor/imgs'
import SVGforms from "./doorForm/svg";
import SVGmodels from "./doorModel/svg";
import GlassColor from "./glassColor";
import GlassColorImgs from "./glassColor/imgs";
import Options from "./options";
type LeftMenuProps={
    onSelectForm:any,
}

type LeftMenuState={
    selectedForm:any,
    selectedModel:any,
    selectedFrontColor:any,
    selectedBackColor:any,
    mountedForm:any,
    mountedModel:any,
    selectedGlassColor:any,
    height:any,
    width:any,
    view:number
}

export interface Form {
    setInnerComponent: any;
}
export interface Model {

}

type LeftMenuItem={
    label:string,
    icon:string,
    key:number,
    component:React.ReactElement
}
export class LeftMenu extends React.Component<LeftMenuProps,LeftMenuState> {
    private readonly secondMenu:React.RefObject<any>;
    leftMenuItems:Array<LeftMenuItem>
    constructor(props: LeftMenuProps, context: any) {
        super(props, context);
        this.secondMenu=React.createRef()
        let selectedModel = <SVGmodels.Model1.component onMount={(model:any)=>this.onMountModel(model)}/>;
        this.state={
            selectedForm:<SVGforms.Form2.component onMount={(form:any)=>this.onMountForm(form)} defaultModel={<div/>}/>,
            selectedModel: selectedModel,
            selectedFrontColor:ColorImgs.Color1,
            selectedBackColor:ColorImgs.Color1,
            selectedGlassColor:GlassColorImgs.Color1,
            mountedForm:null,
            mountedModel:null,
            height:2100,
            width:1000,
            view:1
        }
        this.leftMenuItems = [
            {label: "форма дверей", icon: './icon', key: 1,
                component: <DoorForm onSwitch={(form:Form)=>this.onSelectForm(form)} onMountForm={(form:any)=>this.onMountForm(form)} defaultModel={selectedModel}/>},
            {label: "модель", icon: './icon', key: 2,
                component: <DoorModel onSwitch={(item:any)=>this.onSelectModel(item)} onMountModel={(model:any)=>this.onMountModel(model)}/>},
            {label: "цвет снаружи", icon: './icon', key: 3,
                component: <DoorColor onSwitch={(item:string)=>this.onSelectColor(item,true)}/>},
            {label: "цвет внутри", icon: './icon', key: 4,
                component: <DoorColor onSwitch={(item:string)=>this.onSelectColor(item,false)}/>},
            {label: "стекло", icon: './icon', key: 5,
                component: <GlassColor onSwitch={(item:string)=>this.onSelectGlass(item)}/>},
            {label: "опции", icon: './icon', key: 6,
                component: <Options onSwitch={(item:string)=>this.onChangeOptions(item)}/>}
        ]
        props.onSelectForm(this.state.selectedForm)
        //this.onSelectModel(this.state.selectedModel)
        //this.onSelectColor(this.state.selectedBGColor)
    }

    setView=(view:number)=>{
        this.setState({view},()=>{
            if(this.state.mountedForm.setView) {
                this.state.mountedForm.setView(view)
            }
            if(view===1){
                this.onSelectColor(this.state.selectedFrontColor,true)
            }
            else{
                this.onSelectColor(this.state.selectedBackColor,false)
            }
        })
    }
    onChangeOptions=(options:any)=>{
        this.setState({
            height:options.height,
            width:options.width
        },()=>{
            if(this.state.mountedForm && this.state.mountedForm.setOptions) {
                this.state.mountedForm.setOptions(options)
            }
            if(this.state.mountedModel && this.state.mountedModel.setOptions) {
                this.state.mountedModel.setOptions(options)
            }
        })
    }

    onMountForm=(form:any)=>{
        this.setState({
            mountedForm:form
        },()=>{
            this.onSelectModel(this.state.selectedModel)
            if(this.state.mountedForm.setView) {
                this.state.mountedForm.setView(this.state.view)
            }
        })
    }

    onMountModel=(model:any)=>{
        this.setState({
            mountedModel:model
        },()=>{
            this.onSelectColor(this.state.selectedFrontColor,true)
            this.onSelectColor(this.state.selectedBackColor,false)
            this.onSelectGlass(this.state.selectedGlassColor)
        })
    }
    onSelectModel=(model:any)=>{
        this.setState({selectedModel:model},()=>{
            if(this.state.mountedForm.setInnerComponent) {
                this.state.mountedForm.setInnerComponent(model)
            }

        })
    }

    onSelectForm=(form:Form)=>{
        this.setState({selectedForm:form},()=>{
            this.props.onSelectForm(form)

        })


    }

    onSelectColor=(color:string,view:boolean)=>{
        const targetColor={selectedFrontColor:this.state.selectedFrontColor,selectedBackColor:this.state.selectedBackColor}
        if(view){
            targetColor.selectedFrontColor=color
        }
        else{
            targetColor.selectedBackColor=color
        }
        this.setState(targetColor,()=>{
            if((view && this.state.view===1)) {
                if (this.state.mountedModel && this.state.mountedModel.setDoorColor) {
                    this.state.mountedModel.setDoorColor(this.state.selectedFrontColor)
                }
                if (this.state.mountedForm && this.state.mountedForm.setColor) {
                    this.state.mountedForm.setColor(this.state.selectedFrontColor)
                }
            }
            else if(!view && this.state.view===-1){
                if (this.state.mountedModel && this.state.mountedModel.setDoorColor) {
                    this.state.mountedModel.setDoorColor(this.state.selectedBackColor)
                }
                if (this.state.mountedForm && this.state.mountedForm.setColor) {
                    this.state.mountedForm.setColor(this.state.selectedBackColor)
                }
            }
        })
    }

    onSelectGlass=(color:string)=>{
        this.setState({selectedGlassColor:color},()=>{
            if(this.state.mountedModel && this.state.mountedModel.setGlassColor){
                this.state.mountedModel.setGlassColor(color)
            }
            if(this.state.mountedForm && this.state.mountedForm.setGlassColor) {
                this.state.mountedForm.setGlassColor(color)
            }
        })
    }

    render = () => {
        return (
            <div style={{width:'500px'}}>
                <Grid container spacing={2} >
                    <Grid item xs={6}>
                        <Box
                            sx={{width: 250}}
                            role="presentation"
                        >
                            <List>
                                {this.leftMenuItems.map((item, index) => (
                                    <ListItem button key={item.key}
                                              onClick={() => this.onClickItem(item.label, item.component)}>
                                        <ListItemIcon>
                                            {index % 2 === 0 ? <InboxIcon/> : <MailIcon/>}
                                        </ListItemIcon>
                                        <Typography>{item.label}</Typography>
                                    </ListItem>
                                ))}
                            </List>
                        </Box>
                    </Grid>
                    <Grid item xs={6}>
                        <SecondMenu ref={this.secondMenu}/>
                    </Grid>
                </Grid>
            </div>
        );
    }

    onClickItem = (label: string, component: ReactNode) => {
        this.secondMenu.current.show(label,component)
    }
}

