import React from 'react';
import './App.css';
import {LeftMenu} from './leftMenu'
import {MainContent} from "./mainContent";
import InOutButtons from "./inOutButtons";

type AppProps = {

}

type AppState = {
    image1:any,
    component:any,
    view:number
}

export class App extends React.Component<AppProps,AppState>{

    private readonly leftMenu:React.RefObject<any>;
    constructor(props: AppProps, context: any) {
        super(props, context);
        this.state={
            image1:"",
            component:null,
            view:1
        }
        this.leftMenu=React.createRef()
    }

    onSwitchForm=(item:any)=>{
        this.setState({component:item})
    }

    onSwitchView=(view:any)=>{
        this.setState({
            view
        },()=>{
            this.leftMenu.current.setView(view)
        })
    }
    render() {
        return (
            <div className="App">
                <header className="App-header">

                    <MainContent component={this.state.component}/>
                    <LeftMenu onSelectForm={this.onSwitchForm} ref={this.leftMenu}/>
                    <InOutButtons onSwitchView={this.onSwitchView}/>
                </header>
            </div>
        );
    }
}

export default App;
