import * as React from "react";
import './index.css'
import {ReactNode} from "react";
type MainProps = {
    component:any
}

export class MainContent extends React.Component<MainProps> {

    constructor(props: MainProps, context: any) {
        super(props, context);
    }

    render() {
        return <div className="content">
            {this.props.component}
        </div>;
    }
}