import {Component} from "react";
import React from "react";
import Form2Preview from './form2.svg'
import Models from "../../doorModel/svg";
import {Form} from "../../index";
import './index.css'
import {BaseForm} from "./baseForm";

class Form2 extends BaseForm implements Form {

    render() {
        return (<div className="svgContent">
            <svg width="73" height="90" fill="none" xmlns="http://www.w3.org/2000/svg" transform={this.getBaseScale()}>
                <pattern id="glassPattern" width="66" height="12.73">
                    <image href={this.state.glassColor}/>
                </pattern>
                <pattern id="formPattern" width="143" height="298">
                    <image href={this.state.bgColor} className="fillBG"/>
                </pattern>
                <path d={"M1 1H" + this.state.left + "V89H1V1Z"} transform={this.getScale(1, 0)}
                      fill="url(#glassPattern)" fillOpacity="0.4" stroke="url(#formPattern)" strokeWidth="2"/>

                <g transform={this.getScale(1, 1)}>
                    <rect x="25" y="1" width="47" height="88" stroke="url(#formPattern)" strokeWidth="2"/>
                    <svg x="12">
                        {this.state.innerComponent}
                    </svg>
                </g>
            </svg>
        </div>)
    }
}

export default {component: Form2, preview: Form2Preview}
