import {Component} from "react";
import React from "react";
import Form3Preview from './form3.svg'
import {Form} from "../../index";
import Models from "../../doorModel/svg";
import './index.css'
import {BaseForm} from "./baseForm";


class Form3 extends BaseForm implements Form {

    render() {
        return (<div className="svgContent">
            <svg width="73" height="90" fill="none" xmlns="http://www.w3.org/2000/svg" transform={this.getBaseScale()}>
                <pattern id="glassPattern" width="66" height="12.73">
                    <image href={this.state.glassColor}/>
                </pattern>
                <pattern id="formPattern" width="143" height="298">
                    <image href={this.state.bgColor} className="fillBG"/>
                </pattern>

                <path d="M48 1H72V89H48V1Z" fill="url(#glassPattern)" fillOpacity="0.4" stroke="url(#formPattern)"
                      strokeWidth="2" transform={this.getScale(1, 0)}/>
                <g transform={this.getScale(1, 1)}>
                    <rect x="1" y="1" width="47" height="88" stroke="url(#formPattern)" strokeWidth="2"/>
                    <svg x="-12">
                        {this.state.innerComponent}
                    </svg>
                </g>
            </svg>
        </div>)
    }
}

export default {component: Form3, preview: Form3Preview}
