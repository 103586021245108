import {Component} from "react";
import React from "react";

type ModelProps = {
    onMount: any
}

type ModelState = {
    bgColor: any,
    glassColor: string,
    dh:number,
    dw:number
}

export abstract class BaseModel extends Component<ModelProps, ModelState> {

    baseOptions={
        width:1000,
        height:1800
    }

    protected constructor(props: Readonly<ModelProps> | ModelProps) {
        super(props);
        this.state = {
            bgColor: "white",
            glassColor: "red",
            dh:1,
            dw:1
        }
    }

    componentDidMount() {
        if (this.props.onMount) {
            this.props.onMount(this)
        }
    }

    setDoorColor = (color: any) => {
        this.setState({
            bgColor: color
        })
    }

    setGlassColor = (color: string) => {
        this.setState({glassColor: color})
    }

    setOptions=(options:any)=>{
        this.setState({
            dw:options.width/this.baseOptions.width,
            dh:options.height/this.baseOptions.height
        })
    }

    getScale=()=>{
        return "scale("+this.state.dw+","+this.state.dh+")"
    }
}