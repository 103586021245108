import {Component} from "react";
import React from "react";
import Model2Preview from './model2.svg'
import {BaseModel} from "./baseModel";
import './model2.css'

type Model2Props = {
    onMount:any
}

type Model2State = {
    bgColor:any
}

class Model2 extends BaseModel {


    render() {
        return (
            <svg id="Horisontal" xmlns="http://www.w3.org/2000/svg" 
                 viewBox="0 0 143 298">
                <defs>
                    <style>

                    </style>
                    <linearGradient id="BG-gradient" x1="4.13" y1="148.59" x2="145.52" y2="148.59"
                                    gradientUnits="userSpaceOnUse">
                        <stop offset="0.25" stopColor="#f9f9f9"/>
                        <stop offset="0.67" stopColor="#e8e8e8"/>
                        <stop offset="0.79" stopColor="#e2e2e2"/>
                    </linearGradient>
                    <linearGradient id="handle-gradient" x1="11.3" y1="150.42" x2="16.95" y2="150.42"
                                    gradientUnits="userSpaceOnUse">
                        <stop offset="0.01" stopColor="#babbbc"/>
                        <stop offset="0.29" stopColor="#989a9e"/>
                        <stop offset="0.65" stopColor="#bdbdc1"/>
                        <stop offset="0.87" stopColor="#9d9fa2"/>
                        <stop offset="1" stopColor="#a6a8aa"/>
                        <stop offset="1" stopColor="#c2c5c9"/>
                    </linearGradient>
                    <linearGradient id="inox-gradient" x1="28.41" y1="171.63" x2="113.25" y2="171.63"
                                    />
                    <linearGradient id="inox-gradient-2" data-name="inox" x1="28.41" y1="129.21" x2="113.25" y2="129.21"
                                    />
                    <linearGradient id="inox-gradient-3" data-name="inox" x1="28.41" y1="86.79" x2="113.25" y2="86.79"
                                    />
                    <linearGradient id="inox-gradient-4" data-name="inox" x1="28.41" y1="214.05" x2="113.25" y2="214.05"
                                    />
                    <linearGradient id="glass-gradient" x1="70.82" y1="177.99" x2="70.82" y2="165.27"
                                    gradientUnits="userSpaceOnUse">
                        <stop offset="0" stopColor="#f4f4f4"/>
                        <stop offset="0.5" stopColor="#dee8e8"/>
                        <stop offset="1" stopColor="#d1cece"/>
                    </linearGradient>
                    <linearGradient id="glass-gradient-2" data-name="glass-gradient" x1="70.82" y1="220.41" x2="70.82"
                                    y2="207.68" />
                    <linearGradient id="glass-gradient-3" data-name="glass-gradient" x1="70.82" y1="135.57" x2="70.82"
                                    y2="122.85" />
                    <linearGradient id="glass-gradient-4" data-name="glass-gradient" x1="70.82" y1="93.15" x2="70.82"
                                    y2="80.43" />
                    <filter id="inset-shadow">
                        <feOffset dx="0" dy="0"/>
                        <feGaussianBlur stdDeviation="1" result="offset-blur"/>
                        <feComposite operator="out" in="SourceGraphic" in2="offset-blur" result="inverse"/>
                        <feFlood floodColor="black" floodOpacity="1" result="color"/>
                        <feComposite operator="in" in="color" in2="inverse" result="shadow"/>
                        <feComponentTransfer in="shadow" result="shadow">
                            <feFuncA type="linear" slope="1.25"/>
                        </feComponentTransfer>
                        <feComposite operator="over" in="shadow" in2="SourceGraphic"/>
                    </filter>
                </defs>
                <pattern id="pattern" width="143" height="298">
                    <image href={this.state.bgColor} className="fillBG"/>
                </pattern>
                <pattern id="glassPattern" width="55.14"  height="12.73">
                    <image href={this.state.glassColor}/>
                </pattern>
                <title>model</title>
                <g id="H02">
                    <rect id="BG" x="0" y="0" width="143" height="298" fill="url(#pattern)"/>
                    <polyline id="doorstroke" className="cls-2"
                              points="5.79 295.05 135.86 295.05 135.86 5.79 5.79 5.79 5.79 295.05"/>
                    <polyline id="doorstroke2" className="cls-2"
                              points="22.18 278.66 119.46 278.66 119.46 22.18 22.18 22.18 22.18 278.66"/>
                    <rect id="handle" className="handle" x="11.3" y="65.58" width="5.66" height="169.5"/>
                    <rect id="inox" data-name="inox" className="cls-4" x="28.41" y="161.02" width="84.84"
                          height="21.21"/>
                    <rect id="inox-2" data-name="inox" className="cls-5" x="28.41" y="118.61" width="84.84"
                          height="21.21"/>
                    <rect id="inox-3" data-name="inox" className="cls-6" x="28.41" y="76.19" width="84.84"
                          height="21.21"/>
                    <polyline id="inox-4" data-name="inox" className="cls-7"
                              points="28.41 203.44 28.41 224.65 113.25 224.65 113.25 203.44 28.41 203.44"/>
                    <rect id="glass" fill="url(#glassPattern)" x="43.25" y="165.27" width="55.14" height="12.73"/>
                    <rect id="glass-2" data-name="glass" fill="url(#glassPattern)" x="43.25" y="207.68" width="55.14"
                          height="12.73"/>
                    <rect id="glass-3" data-name="glass" fill="url(#glassPattern)"  x="43.25" y="122.85" width="55.14"
                          height="12.73"/>
                    <rect id="glass-4" data-name="glass" fill="url(#glassPattern)" x="43.25" y="80.43" width="55.14"
                          height="12.73"/>
                </g>
            </svg>
        )
    }
}

export default {component:Model2,preview:Model2Preview}
