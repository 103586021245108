import {Component} from "react";
import React from "react";
import {Grid, Typography} from "@mui/material";
import ColorImgs from "./imgs";
import './index.css'

type GlassColorProps = {
    onSwitch:any
}

type GlassColorState = {}

class GlassColor extends Component<GlassColorProps, GlassColorState> {
    colorItems1=[
        {label:"g1",preview:ColorImgs.Color1,component:ColorImgs.Color1},
        {label:"g2",preview:ColorImgs.Color2,component:ColorImgs.Color2}
    ]
    colorItems2=[
        {label:"g3",preview:ColorImgs.Color3,component:ColorImgs.Color3},
    ]


    constructor(props: GlassColorProps, context: any) {
        super(props, context)

    }

    onClickItem=(item:any)=>{
        this.props.onSwitch(item)
    }

    render() {
        return (            <div>
            <Grid container>
                <Grid item xs={6}>
                    {this.colorItems1.map((item)=>{
                        return <div className='glassItem' onClick={()=>this.onClickItem(item.component)}>
                            <img src={item.preview} width="100px" height="200px"/>
                            <Typography>{item.label}</Typography>
                        </div>
                    })}
                </Grid>
                <Grid item xs={6}>
                    {this.colorItems2.map((item)=>{
                        return <div className='glassItem' onClick={()=>this.onClickItem(item.component)}>
                            <img src={item.preview} width="100px" height="200px"/>
                            <Typography>{item.label}</Typography>
                        </div>
                    })}
                </Grid>
            </Grid>
        </div>)
    }
}

export default GlassColor
